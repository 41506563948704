.hover-float {
    transition: transform 250ms;
}

.hover-float:hover {
    transform: translateY(-5px);
}

.hover-float:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

.yajra-datatable {
    width: 100% !important
}


/* width */

 ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}


/* Track */

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #909090;
    border-radius: 10px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #cccccc;
}


/* End Scrollbar */

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.dt-buttons {
    display: block;
    margin-bottom: 15px;
}

.dataTables_length {
    float: left;
}

.hoverable .banner-top {
    transition: all 0.5s;
    z-index: -1;
    margin-bottom: -40px;
    border-radius: 15px 15px 0 0;
}

.hoverable:hover {
    transition: all 0.5s;
}

.hoverable:hover .banner-top {
    transform: translateY(-35px);
}

.banner-bot {
    border-radius: 0 0 15px 15px
}

.banners {
    margin-bottom: 40px;
}

.maxline1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.maxline2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.maxline8 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}

.maxline3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.maxline4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
